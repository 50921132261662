import { css, cx } from '@emotion/css';
import facepaint from 'facepaint';
const breakpoints = [576, 801, 1200];
export const mq = facepaint(
    breakpoints.map(bp => `@media (min-width: ${bp}px)`)
);

export const BACKGROUND_COLOR = '#242730!important';
export const TEXT_COLOR = 'LightGray!important';

export const flexHoriz = css({
    display: 'flex!important',
    flexFlow: 'row',
    alignItems: 'flex-start'
});

export const flexHorizCenter = css({
    display: 'flex!important',
    flexFlow: 'row',
    alignItems: 'center'
});

export const flexVert = css({
    display: 'flex!important',
    flexFlow: 'column',
    alignItems: 'flex-start'
});

export const flexVertCenter = css({
    display: 'flex!important',
    flexFlow: 'column wrap',
    alignItems: 'center'
});

export const siteBackgroundColor = css({
    backgroundColor: BACKGROUND_COLOR,
});

export const siteTextColor = css({
    color: TEXT_COLOR
});

export const siteTextFont = css({
    fontFamily: 'Vera!important'
})

export const siteHeaderText = cx(siteTextColor, siteTextFont, css({
    fontSize: "20px!important",
    fontWeight: "bold",
}));

export const siteSubHeaderText = cx(siteTextColor, siteTextFont, css({
    fontSize: "18px!important",
    fontWeight: "bold",
}));

export const siteMetaText = cx(siteTextColor, siteTextFont, css({
    fontSize: "14px!important",
    fontStyle: "italic",
}));

export const siteSubText = cx(siteTextColor, siteTextFont, css({
    fontSize: "14px!important",
    fontWeight: "bold",
}));

export const siteBodyText = cx(siteTextColor, siteTextFont, css({
    fontSize: "16px!important",
}));

export const siteCodeLink = css({
    fontSize: "16px!important",
    fontFamily: 'courier',
    textDecoration: 'underline'
});
